import { Select, MenuItem, Checkbox, ListItemText, Box, ListSubheader, Typography, Chip } from '@material-ui/core';
import React, { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import styled from 'styled-components';

import { ReactComponent as DeleteIcon } from '../../../assets/icons/thrash-icon.svg';
import { attributeValuesMapSelector, attributeValuesSelector } from '../assignmentConfig.selectors';

const MENU_PROPS = {
  disablePortal: true,
  PaperProps: {
    style: {
      maxHeight: 300,
      width: 300,
    },
  },
  getContentAnchorEl: null,
};

interface IProps {
  index: number;
  selectedAttributes: any[];
  onChange: (value: number[], index: number) => void;
  onRemoveSelect: (index: number) => void;
  isDeleteEnabled: boolean;
  placeholder: string;
  type: 'target' | 'exclude';
}

const AttributesSelect: React.FC<IProps> = ({
  index,
  selectedAttributes,
  onChange,
  isDeleteEnabled,
  onRemoveSelect,
  placeholder,
  type,
}) => {
  const { t } = useTranslation();
  const attributeValuesMap = useSelector(attributeValuesMapSelector);
  const attributes = useSelector(attributeValuesSelector);

  const handleChange = useCallback(
    (event) => {
      if (!event.target.value[0]) {
        return;
      }
      onChange(event.target.value, index);
    },
    [index, onChange],
  );

  const handleDelete = useCallback(
    (value) => {
      onChange(
        selectedAttributes.filter((selected) => selected !== value),
        index,
      );
    },
    [index, onChange, selectedAttributes],
  );

  const handleRemoveSelect = useCallback(() => {
    onRemoveSelect(index);
  }, [index, onRemoveSelect]);

  const renderValue = useCallback(
    (selected) => {
      if (selected.length === 0) {
        return <S.Placeholder>{placeholder}</S.Placeholder>;
      }

      return (
        <S.Box display='flex' flexWrap='wrap'>
          {selected.map((value) => (
            <SelectedAttribute key={value.id} value={value} onDelete={handleDelete} />
          ))}
        </S.Box>
      );
    },
    [handleDelete, placeholder],
  );

  return (
    <S.Container>
      <S.Content>
        <S.Select
          multiple
          disableUnderline
          displayEmpty
          value={selectedAttributes}
          onChange={handleChange}
          renderValue={renderValue}
          MenuProps={MENU_PROPS}
        >
          {attributes.map((attributeTitle) => [
            <S.ListSubheader disabled key={attributeTitle.attributesTitleId}>
              <S.SubheaderText variant='subtitle2'>{attributeTitle.attributesTitle}</S.SubheaderText>
            </S.ListSubheader>,
            ...attributeTitle.attributeValues.map((option) => (
              <MenuItem key={option.id} value={option.id}>
                <Box display='flex' alignItems='center'>
                  <Typography>{option.name}</Typography>
                </Box>
              </MenuItem>
            )),
          ])}
        </S.Select>
        {isDeleteEnabled && (
          <S.DeleteButton onClick={handleRemoveSelect}>
            <DeleteIcon />
          </S.DeleteButton>
        )}
      </S.Content>
      {!!selectedAttributes.length && (
        <S.ExplanationText>
          {t(`assignmentConfig.updateConfigModal.${type}UsersWhoMatch`)}{' '}
          {selectedAttributes.map((id) => attributeValuesMap[id]?.name).join(', ')}
        </S.ExplanationText>
      )}

      {!!selectedAttributes.length && (
        <S.OrContainer>
          <S.Line />
          <S.OrLabel>{t(`assignmentConfig.updateConfigModal.${type}OrLabel`)}</S.OrLabel>
          <S.Line />
        </S.OrContainer>
      )}
    </S.Container>
  );
};

const SelectedAttribute = ({ value, onDelete }) => {
  const { t } = useTranslation();
  const attributeValuesMap = useSelector(attributeValuesMapSelector);
  const handleMouseDown = useCallback((event) => {
    event.stopPropagation();
  }, []);

  const handleDelete = useCallback(
    (event) => {
      event.stopPropagation();
      onDelete(value);
    },
    [onDelete, value],
  );

  return (
    <S.Chip
      label={attributeValuesMap[value]?.name || t('assignmentConfig.table.attributeValueNotExists')}
      onDelete={handleDelete}
      onMouseDown={handleMouseDown}
    />
  );
};

const S = {
  Container: styled.div`
    display: flex;
    flex-direction: column;
    width: 100%;
  `,
  Content: styled.div`
    display: flex;
    flex-direction: row;
    width: 100%;
  `,
  Select: styled(Select)`
    min-height: 50px;
    width: 100%;
    border: 1px solid #d1d1d6;
    border-radius: 10px;
    background-color: ${({ theme }) => theme.colors.white};
    overflow: hidden;
  `,
  DeleteButton: styled.div`
    align-items: center;
    justify-content: center;
    display: flex;
    margin-left: 10px;
    cursor: pointer;
    min-width: 50px;
    max-width: 50px;
    height: 50px;
    border: 1px solid #d1d1d6;
    border-radius: 10px;
  `,
  MenuItem: styled(MenuItem)``,
  Checkbox: styled(Checkbox)``,
  ListItemText: styled(ListItemText)``,
  Chip: styled(Chip)`
    height: 30px;
    margin: 2px;
  `,
  ListSubheader: styled(ListSubheader)`
    font-weight: bold;
    background-color: ${({ theme }) => theme.colors.white};
    margin-top: 10px;
    pointer-events: none;
  `,
  SubheaderText: styled(Typography)`
    color: gray;
  `,
  Placeholder: styled(Typography)`
    color: gray;
    margin: 0 10px;
  `,
  ExplanationText: styled(Typography)`
    font-size: 14px;
    color: ${({ theme }) => theme.colors.gray19};
    margin: 0 5px;
  `,
  OrContainer: styled.div`
    display: flex;
    justify-content: center;
    margin: 10px 14px;
  `,
  OrLabel: styled.span`
    font-size: 14px;
    text-align: center;
    white-space: nowrap;
    color: ${({ theme }) => theme.colors.gray19};
  `,
  Line: styled.div`
    border-top: 1px solid #d1d1d6;
    width: 100%;
    margin: 0 10px;
    align-self: center;
  `,
  Box: styled(Box)`
    padding: 1px 5px 0;
  `,
};

export default AttributesSelect;
