import moment from 'moment-timezone';

import { BEEKEEPER_ORG_IDS } from '../constants/constants';
import { IOrgData, IOrgDataOption } from '../types/org';

interface IFormatDatasPayload {
  data1Title?: string;
  data2Title?: string;
  data3Title?: string;
  data4Title?: string;
  data5Title?: string;
  data6Title?: string;
  data7Title?: string;
  data8Title?: string;
  data1?: IOrgDataOption;
  data2?: IOrgDataOption;
  data3?: IOrgDataOption;
  data4?: IOrgDataOption;
  data5?: IOrgDataOption;
  data6?: IOrgDataOption;
  data7?: IOrgDataOption;
  data8?: IOrgDataOption;
}

export const formatDatas = (payload: IFormatDatasPayload): IOrgData[] => {
  const datas: IOrgData[] = [];
  // if no datas, then return empty
  let isEmpty = true;

  for (let i = 0; i < 8; i++) {
    const title = payload[`data${i + 1}Title`];
    if (title) {
      // data exists
      isEmpty = false;
    }
    const options = payload[`data${i + 1}`];
    if (title && options) {
      datas.push({ title, options, dataFieldName: `data${i + 1}`, dataIdx: i });
    }
  }

  return isEmpty ? [] : datas;
};

export const isMobile = (): boolean => window.innerWidth <= 600;

export const getUserLocale = (): string => {
  const locale = navigator.language || navigator.languages[0];
  return locale || 'en-US';
};

export const getUserUtcOffsetInHours = (): number => {
  const timezone = moment.tz.guess();
  const offsetInMinutes = moment.tz(timezone).utcOffset();
  return offsetInMinutes / 60;
};

export const isBeekeeperOrganization = (orgId: number) => BEEKEEPER_ORG_IDS.includes(orgId);
