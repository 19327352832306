import { isProd } from '../utils/env';

export const FRONTLINER_URL = isProd() ? 'https://mybiteshares.com' : 'https://small-bites.com';

export const REACT_DATA_GRID_LICENSE_KEY =
  'AppName=BitesLearningApp,Company=BitesLearning,ExpiryDate=2023-09-06,LicenseDeveloperCount=1,LicenseType=single_app,Ref=BitesLearningLicenseRef,Z=719079754592594105-493784202-2084886945-15273048951543113642';
export const APP = 'dashboard';
export const MS_PERSONAL_TENANT_ID = '9188040d-6c67-4c5b-b112-36a304b66dad';

export const URL_ACCESS_TOKEN_PLACEHOLDER = 'ACCESS_TOKEN';
export const URL_ERROR_CODE_PLACEHOLDER = 'ERROR_CODE';
export const URL_ACTION_SOCIAL_AUTH = 'social-auth';
export const IP_BASE_TOKEN = '6jpbXFjnWIqxDA9QrFHqPkPpf1MkQFPs5f2w8Sz7';

export const SCHEDULE_DISTRIBUTION_DELAY_HOURS = 2.5;
export const SCHEDULE_DISTRIBUTION_DELAY_24_HOURS = 24;
export const SCHEDULE_DISTRIBUTION_DELAY_48_HOURS = 48;
export const TERMS_CONDITIONS_URL = 'https://mybites.io/termsconditions/';
export const BEEKEEPER_ORG_IDS: number[] = [11363];
export const BEEKEEPER_SSO_URL = 'https://leonardo.beekeeper.io/auth_link';
export const SAML_METADATA_URL = 'https://api.mybiteshares.com/api/sso/metadata';

//HP
export const MAIL_MERGE_ORG_IDS = [23, 4314, 19505];

export enum Features {
  SMS_DISTRIBUTION = 1,
  BEEKEEPER_DISTRIBUTION = 3,
  EMAIL_DISTRIBUTION = 682849,
  ASK_AI_FEED = 830113,
  CONTENT_ASSIGNMENT = 514483,
  '24H_REMINDER' = 5,
  SLACK_DISTRIBUTION = 1449583,
  SLACK_INTEGRATION = 8698190,
  REMAINDER_DELAY_48 = 10531927,
}

export const BEEKEEPER_DEFAULT_MESSAGE_TEXT =
  'Hey {Full Name}!\nCheck out the new bite from {Organization Name}!\n\n{Bite Name}';

export const TEAMS_DEFAULT_MESSAGE_TEXT = 'Hey {Full Name}!\nCheck out the new bite from {Organization Name}!';

export const SLACK_DEFAULT_MESSAGE_TEXT = 'Hey {Full Name}!\nCheck out the new bite from {Organization Name}!';

export const INTERCOM_ARTICLES = {
  howToShareContent: '9380230',
};

export enum EYesNo {
  YES = 'Yes',
  NO = 'No',
}
