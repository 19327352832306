import { createAction, createSlice } from '@reduxjs/toolkit';

import { TAssignmentAttributesMapping } from './assignmentConfig.api';

const initialState = {
  content: [],
  attributeValues: [],
  attributeValuesMap: {},
};

const ASSIGNMENT_CONFIG = 'ASSIGNMENT_CONFIG';

export interface ISearchAssignmentConfigAction {
  page: number;
  pageSize: number;
  onSuccess: (response: any) => void;
  onError: (error: any) => void;
}

export const searchConfigs = createAction<ISearchAssignmentConfigAction>('assignmentContent/searchConfigs');
export const createConfig = createAction<{
  config: Omit<TAssignmentAttributesMapping, 'id' | 'orgId' | 'createDate' | 'updateDate'>;
  onSuccess: () => void;
  onError: () => void;
}>('assignmentContent/createConfig');

export const updateConfig = createAction<{
  config: Omit<TAssignmentAttributesMapping, 'orgId' | 'createDate' | 'updateDate'>;
  onSuccess: () => void;
  onError: () => void;
}>('assignmentContent/updateConfig');
export const deleteConfig = createAction<{ configId: string; onSuccess: () => void; onError: () => void }>(
  'assignmentContent/deleteConfig',
);
export const getFeedContent = createAction('assignmentContent/getFeedContent');

export const searchFeedContent = createAction<{
  search?: string;
  onSuccess?: (content: any[]) => void;
  onError?: () => void;
}>('assignmentContent/searchFeedContent');
export const getAttributeValues = createAction('assignmentContent/getAttributeValues');

const assignmentConfigSlice = createSlice({
  name: ASSIGNMENT_CONFIG,
  initialState: initialState,
  reducers: {
    setFeedContent: (state, action) => {
      state.content = action.payload;
    },
    setAttributeValues: (state, action) => {
      state.attributeValues = action.payload;

      const attributeValuesMap = {};
      action.payload.forEach((attributeTitle) => {
        attributeTitle.attributeValues.forEach((attributeValue) => {
          attributeValuesMap[attributeValue.id] = {
            ...attributeValue,
            name: attributeValue.name,
            attributesTitleName: attributeTitle.attributesTitle,
          };
        });
      }, {});

      state.attributeValuesMap = attributeValuesMap;
    },
  },
});

export const { setFeedContent, setAttributeValues } = assignmentConfigSlice.actions;

export default assignmentConfigSlice.reducer;
