import bitesApi from '../../services/bitesApi';

export type TAssignmentAttributesMapping = {
  id: string;
  orgId: number;
  attributeValueIds: number[][];
  exceptAttributeValueIds: number[][];
  content: {
    contentType: 'bite' | 'biteShare' | 'playlist' | 'quiz';
    contentId: number;
    dueDateDelayMinutes: number;
  }[];
  createDate: Date;
  updateDate: Date;
};

export const createConfig = async ({
  orgId,
  assignmentAttributesMapping,
}: {
  orgId: number;
  assignmentAttributesMapping: Omit<TAssignmentAttributesMapping, 'id' | 'orgId' | 'createDate' | 'updateDate'>;
}) => {
  return bitesApi.post(
    `/content/organization/${orgId}/assignment/mapping`,
    { assignmentAttributesMapping: { ...assignmentAttributesMapping, orgId } },
    {},
    { originalBody: true },
  );
};

export const updateConfig = async ({
  orgId,
  assignmentAttributesMapping,
}: {
  orgId: number;
  assignmentAttributesMapping: Omit<TAssignmentAttributesMapping, 'orgId' | 'createDate' | 'updateDate'>;
}) => {
  return bitesApi.patch(
    `/content/organization/${orgId}/assignment/mapping/${assignmentAttributesMapping.id}`,
    { assignmentAttributesMapping: { ...assignmentAttributesMapping, orgId } },
    {},
    { originalBody: true },
  );
};

export const deleteConfig = async ({ orgId, configId }: { orgId: number; configId: string }) => {
  return bitesApi.del(`/content/organization/${orgId}/assignment/mapping/${configId}`);
};

export const searchConfigs = async ({
  orgId,
  pageSize = 25,
  page = 0,
}: {
  orgId: number;
  pageSize: number;
  page: number;
}) => {
  return bitesApi.post(
    `/content/organization/${orgId}/assignment/mapping/search`,
    {
      filters: {
        orgId,
      },
      pageSize,
      page,
    },
    {},
    { originalData: true, originalBody: true },
  );
};

export enum IFeedObjectType {
  bite = 'bite',
  playlist = 'playlist',
  quiz = 'quiz',
  all = 'all',
}

interface IGetFeedRequest {
  orgId: number;
  nextPage: number;
  search?: string;
  type?: IFeedObjectType[];
  pageSize?: number;
}

export const getFeed = ({ orgId, search, nextPage, type = [IFeedObjectType.all], pageSize = 20 }: IGetFeedRequest) =>
  bitesApi.post('/feed/', {
    filters: {
      organization: orgId,
      type,
      search: search || undefined,
    },
    sort: {
      field: 'created_at',
      direction: 'desc',
    },
    pagination: true,
    page: nextPage,
    page_size: pageSize,
  });
