import { toast } from 'react-toastify';
import { all, put, select, takeLatest } from 'redux-saga/effects';

import { IAction } from '../../types/types';
import { getBiteShare } from '../bite/bite.api';
import { fetchBitesDataSaga } from '../bites/bites.saga';
import { bitesMapSelector, setBiteSharesMap } from '../bites/bites.slice';
import * as orgCalls from '../org/org.api';
import { selectOrg } from '../org/org.slice';
import { getPlaylist } from '../playlist/playlist.api';
import { playlistsMapSelector, setPlaylistMap } from '../playlists/playlists.slice';
import { getQuiz } from '../quiz/quiz.api';
import { quizzesMapSelector, setQuizzesMap } from '../quiz/quiz.slice';

import * as calls from './assignmentConfig.api';
import { IFeedObjectType, TAssignmentAttributesMapping } from './assignmentConfig.api';
import {
  getAttributeValues,
  setAttributeValues,
  ISearchAssignmentConfigAction,
  searchConfigs,
  getFeedContent,
  setFeedContent,
  createConfig,
  deleteConfig,
  updateConfig,
  searchFeedContent,
} from './assignmentConfig.slice';

function* createConfigSaga({
  payload: {
    config: { exceptAttributeValueIds, attributeValueIds, content },
    onSuccess,
    onError,
  },
}: IAction<{
  config: Omit<TAssignmentAttributesMapping, 'id' | 'orgId' | 'createDate' | 'updateDate'>;
  onSuccess: () => void;
  onError: () => void;
}>) {
  const org = yield select(selectOrg);
  try {
    yield calls.createConfig({
      orgId: org.id,
      assignmentAttributesMapping: {
        exceptAttributeValueIds,
        attributeValueIds,
        content,
      },
    });

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (error) {
    if (typeof onError === 'function') {
      onError();
    }
    toast.error(error.message);
  }
}

function* updateConfigSaga({
  payload: {
    config: { id, exceptAttributeValueIds, attributeValueIds, content },
    onSuccess,
    onError,
  },
}: IAction<{
  config: Omit<TAssignmentAttributesMapping, 'orgId' | 'createDate' | 'updateDate'>;
  onSuccess: () => void;
  onError: () => void;
}>) {
  const org = yield select(selectOrg);
  try {
    yield calls.updateConfig({
      orgId: org.id,
      assignmentAttributesMapping: {
        id,
        exceptAttributeValueIds,
        attributeValueIds,
        content,
      },
    });

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (error) {
    if (typeof onError === 'function') {
      onError();
    }
    toast.error(error.message);
  }
}

function* searchConfigsSaga({
  payload: { page, pageSize, onSuccess, onError },
}: IAction<ISearchAssignmentConfigAction>) {
  try {
    const org = yield select(selectOrg);
    const { data } = yield calls.searchConfigs({ orgId: org.id, page, pageSize });

    const content = data.results.map((item) => item.assignmentAttributesMapping.content).flat();

    const bitesMap = yield select(bitesMapSelector);
    const bitesIds = [
      ...new Set(
        content
          .map((contentItem) =>
            contentItem.contentType === 'bite' && !bitesMap[contentItem.contentId] ? contentItem.contentId : null,
          )
          .filter((biteId) => biteId),
      ),
    ] as number[];
    if (bitesIds.length > 0) {
      yield fetchBitesDataSaga(bitesIds);
    }

    const biteShareIds = [
      ...new Set(
        content
          .map((contentItem) =>
            contentItem.contentType === 'biteShare' && !bitesMap[contentItem.contentId] ? contentItem.contentId : null,
          )
          .filter((biteId) => biteId),
      ),
    ] as number[];

    if (biteShareIds.length > 0) {
      const biteSharesResponse = yield Promise.allSettled(biteShareIds.map((biteShareId) => getBiteShare(biteShareId)));
      yield put(
        setBiteSharesMap(
          biteSharesResponse
            .map((response) => response.status === 'fulfilled' && response.value.data.biteShare)
            .filter(Boolean),
        ),
      );
    }

    const playlistsMap = yield select(playlistsMapSelector);
    const playlistIdsToLoad = [
      ...new Set(
        content
          .map((contentItem) =>
            contentItem.contentType === 'playlist' && !playlistsMap[contentItem.contentId]
              ? contentItem.contentId
              : null,
          )
          .filter((playlistId) => playlistId),
      ),
    ] as number[];
    if (playlistIdsToLoad.length > 0) {
      const playlistsResponse = yield Promise.allSettled(
        playlistIdsToLoad.map((playlistId) => getPlaylist(playlistId)),
      );
      yield put(
        setPlaylistMap(
          playlistsResponse.reduce((acc, response) => {
            if (response.status === 'fulfilled') {
              acc[response.value.data.id] = response.value.data;
            }
            return acc;
          }, {}),
        ),
      );
    }

    const quizzesMap = yield select(quizzesMapSelector);
    const quizIdsToLoad = [
      ...new Set(
        content
          .map((contentItem) =>
            contentItem.contentType === 'quiz' && !quizzesMap[contentItem.contentId] ? contentItem.contentId : null,
          )
          .filter((quizId) => quizId),
      ),
    ] as number[];

    if (quizIdsToLoad.length > 0) {
      const quizzesResponse = yield Promise.allSettled(quizIdsToLoad.map((quizId) => getQuiz(quizId)));
      yield put(
        setQuizzesMap(
          quizzesResponse.reduce((acc, response) => {
            if (response.status === 'fulfilled') {
              acc[response.value.data.id] = response.value.data;
            }
            return acc;
          }, {}),
        ),
      );
    }

    if (typeof onSuccess === 'function') {
      onSuccess({ data: data.results, count: data.count });
    }
  } catch (error) {
    toast.error(error.message);
    if (typeof onError === 'function') {
      onError(error);
    }
  }
}

function* getAttributeValuesSaga() {
  const org = yield select(selectOrg);
  try {
    const { data } = yield orgCalls.fetchOrganizationAttributes(org.id);
    yield put(setAttributeValues(data));
  } catch (error) {
    toast.error(error.message);
  }
}

function* getFeedContentSaga() {
  const org = yield select(selectOrg);
  try {
    const requests = Promise.all([
      calls.getFeed({ orgId: org.id, nextPage: 1, type: [IFeedObjectType.bite], pageSize: 5 }),
      calls.getFeed({ orgId: org.id, nextPage: 1, type: [IFeedObjectType.playlist], pageSize: 5 }),
      calls.getFeed({ orgId: org.id, nextPage: 1, type: [IFeedObjectType.quiz], pageSize: 5 }),
    ]);

    const responses = yield requests;

    const data = responses.map((response) => (response.status === 200 ? response.data.results : [])).flat();

    yield put(setFeedContent(data));
  } catch (error) {
    toast.error(error.message);
  }
}

function* searchFeedContentSaga(
  action: IAction<{ search?: string; onSuccess?: (content: any[]) => void; onError?: () => void }>,
) {
  const org = yield select(selectOrg);
  try {
    const { data } = yield calls.getFeed({ search: action.payload?.search, orgId: org.id, nextPage: 1 });

    if (typeof action.payload?.onSuccess === 'function') {
      action.payload.onSuccess(data.results);
    }
  } catch (error) {
    if (typeof action.payload?.onError === 'function') {
      action.payload.onError();
    }
    toast.error(error.message);
  }
}

function* deleteConfigSaga({
  payload: { configId, onSuccess, onError },
}: IAction<{ onSuccess?: () => void; configId: string; onError: () => void }>) {
  const org = yield select(selectOrg);
  try {
    yield calls.deleteConfig({ orgId: org.id, configId });

    if (typeof onSuccess === 'function') {
      onSuccess();
    }
  } catch (error) {
    if (typeof onError === 'function') {
      onError();
    }
    toast.error(error.message);
  }
}

export default function* assignmentConfigSaga() {
  yield all([
    takeLatest(deleteConfig, deleteConfigSaga),
    takeLatest(createConfig, createConfigSaga),
    takeLatest(updateConfig, updateConfigSaga),
    takeLatest(searchConfigs, searchConfigsSaga),
    takeLatest(getAttributeValues, getAttributeValuesSaga),
    takeLatest(getFeedContent, getFeedContentSaga),
    takeLatest(searchFeedContent, searchFeedContentSaga),
  ]);
}
