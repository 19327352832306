import copy from 'copy-to-clipboard';
import { useCallback } from 'react';
import { useTranslation } from 'react-i18next';
import { useSelector } from 'react-redux';
import { toast } from 'react-toastify';

import { BEEKEEPER_SSO_URL, FRONTLINER_URL, SAML_METADATA_URL } from '../../constants/constants';
import { selectOrg } from '../../features/org/org.slice';
import { ContentType } from '../../features/userAnalysis/components/Table/Table';
import { isBeekeeperOrganization } from '../../utils/utils';

interface IProps {
  contentType: ContentType;
  shareablePath: string;
  subject: string;
}

export const useShareLink = ({ contentType, shareablePath }: IProps) => {
  const { t } = useTranslation();
  const currentOrg = useSelector(selectOrg);
  const handleCopyLink = useCallback(() => {
    toast(
      t('share.noStatsModal.linkCopied', {
        contentType:
          contentType === ContentType.BITE ? 'Bite' : contentType === ContentType.PLAYLIST ? 'Playlist' : 'Quiz',
      }),
    );

    const copyLink = isBeekeeperOrganization(currentOrg.id)
      ? `${BEEKEEPER_SSO_URL}?entity_id=${SAML_METADATA_URL}/&target=${FRONTLINER_URL}/${shareablePath}`
      : `${FRONTLINER_URL}/${shareablePath}`;
    copy(copyLink);
  }, [t, contentType, shareablePath, currentOrg]);

  const onShareSuccessfulyToast = useCallback(() => {
    toast(
      t('share.noStatsModal.successfully', {
        contentType:
          contentType === ContentType.BITE ? 'Bite' : contentType === ContentType.PLAYLIST ? 'Playlist' : 'Quiz',
      }),
    );
  }, [t, contentType]);
  return { handleCopyLink, onShareSuccessfulyToast };
};
